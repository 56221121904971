import React from 'react';
import { Device } from '../interfaces';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
export interface IProps {
  device: Device;
}

const DeviceMarker: React.FC<IProps> = ({ device }) => {
  return (
    <Marker position={L.latLng(device.position.y, device.position.x)}>
      <Popup>{device.name}</Popup>
    </Marker>
  );
};

export default DeviceMarker;
