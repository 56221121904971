import { Device } from '../interfaces';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import React from 'react';

export interface Props {
  position: { x: number; y: number };
  title: string;
  customIcon?: L.Icon;
}

const DefaultMarker: React.FC<Props> = ({
  position,
  title,
  customIcon
}: Props) => {
  const onRefAdded = (ref: any) => {
    if (ref && ref.leafletElement) {
      ref.leafletElement.openPopup();
    }
  };

  console.log('Drawing position ', JSON.stringify(position));
  return (
    <Marker
      position={L.latLng(position.y, position.x)}
      icon={customIcon}
      ref={onRefAdded}
    >
      <Popup autoClose={false}>{title}</Popup>
    </Marker>
  );
};

export default DefaultMarker;
