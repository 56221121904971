import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import { Trip, Device, Point } from '../interfaces';
import DeviceMarker from '../DeviceMarker';
import TripMarker from '../TripMarker';
import L, { LatLng, latLngBounds, LatLngBounds, LatLngLiteral } from 'leaflet';
import DefaultMarker from '../DefaultMarker';

export const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right'
};

export interface IProps {
  bounds?: LatLngBounds;
  center: Point;
  zoom?: number;
  trips?: Trip[];
  devices?: Device[];
  tripMarkerIcon?: L.Icon;
  exceptionMarkerIcon?: L.Icon;
  openPopup?: boolean;
  customTileUrl?: string;
  customTileAttributation?: string;
  setRef?: (ref: any) => void;
  showTripPathLabel?: boolean;
  markers?: {
    title: string;
    position: Point;
  }[];
  debug?: boolean;
  latLngMap?: [string, string];
  minZoom?: number;
  maxZoom?: number;
  children?: () => React.ReactNode;
}

const MapController: React.FC<IProps> = ({
  center,
  zoom,
  trips,
  devices,
  tripMarkerIcon,
  exceptionMarkerIcon,
  openPopup,
  customTileUrl,
  customTileAttributation: customTileAttribution,
  showTripPathLabel,
  markers,
  setRef,
  bounds,
  debug,
  latLngMap,
  children
}: IProps) => {
  const map = useMap();
  const onWindowResized = () => {
    if (map) {
      // map.invalidateSize();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResized);
    return () => {
      window.removeEventListener('resize', onWindowResized);
    };
  });

  const [boundsZoom, setBoundsZoom] = useState<LatLngBounds | null>(null);

  useEffect(() => {
    if (map) {
      if (trips && trips.length > 0) {
        const points = trips[0]?.points || [{ x: 39.220321, y: -100.6811577 }];
        const bounds = L.latLngBounds(
          points.map((point) => L.latLng(point.y, point.x))
        );
        map.fitBounds(bounds);
      }
    }
  }, [devices, zoom, center, trips, map, setRef]);

  const drawDevices = () => {
    if (devices) {
      if (devices.length === 0) {
        return;
      }

      if (devices.length > 1) {
        const latLngs: LatLngLiteral[] = [];
        for (const d of devices) {
          latLngs.push({ lat: d.position.y, lng: d.position.x });
        }

        const bounds = latLngBounds(latLngs);
        if (map) {
          if (debug) {
            console.log('Fitting Bounds: ', JSON.stringify(bounds));
          }
          if (!boundsZoom || !boundsZoom.equals(bounds)) {
            setBoundsZoom(bounds);
            map.fitBounds(bounds, { padding: [50, 50] });
          }
        }
      }

      return devices.map((device, key) => {
        return <DeviceMarker device={device} key={key} />;
      });
    }

    return [];
  };

  const drawTrips = () => {
    if (trips) {
      return trips.map((trip, key) => {
        return (
          <TripMarker
            showTripPathLabel={showTripPathLabel}
            trip={trip}
            key={key}
            customMarkerIcon={tripMarkerIcon}
            customExceptionIcon={exceptionMarkerIcon}
            openPopup={openPopup}
          />
        );
      });
    }
    return [];
  };

  const drawMarkers = () => {
    if (markers) {
      return markers.map((marker, key) => {
        return (
          <DefaultMarker
            key={key}
            position={marker.position}
            title={marker.title}
            customIcon={exceptionMarkerIcon}
          />
        );
      });
    }

    return [];
  };

  return (
    <div>
      <TileLayer
        url={
          customTileUrl || 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        }
        attribution={
          customTileAttribution ||
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }
      />
      {drawMarkers()}
      {drawDevices()}
      {drawTrips()}
      {children && children()}
    </div>
  );
};

const Map: React.FC<IProps> = (props: IProps) => {
  return (
    <MapContainer
      center={
        props.center ? L.latLng(props.center.y, props.center.x) : undefined
      }
      zoom={props.zoom}
      minZoom={props.minZoom || 0}
      maxZoom={props.maxZoom || 20}
    >
      <MapController {...props} />
    </MapContainer>
  );
};

export default Map;
