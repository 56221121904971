import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Trip } from '../interfaces';
import { Marker, Popup, Polyline } from 'react-leaflet';
import L from 'leaflet';
export interface IProps {
  trip: Trip;
  customMarkerIcon?: L.Icon;
  customExceptionIcon?: L.Icon;
  openPopup?: boolean;
  showTripPathLabel?: boolean;
}

const TripMarker: React.FC<IProps> = ({
  trip,
  customMarkerIcon,
  customExceptionIcon,
  openPopup,
  showTripPathLabel
}) => {
  const onRefAdded = (ref: any) => {
    if (ref && ref.leafletElement && openPopup) {
      ref.leafletElement.openPopup();
    }
  };
  return (
    <div>
      {customMarkerIcon ? (
        <div>
          <Marker
            position={L.latLng(trip.startPoint.y, trip.startPoint.x)}
            icon={customMarkerIcon}
            ref={onRefAdded}
          >
            <Popup autoClose={false}>Start of the trip</Popup>
          </Marker>
          <Marker
            position={L.latLng(trip.stopPoint.y, trip.stopPoint.x)}
            icon={customMarkerIcon}
            ref={onRefAdded}
          >
            <Popup autoClose={false}>End of the trip</Popup>
          </Marker>
        </div>
      ) : (
        <div>
          <Marker
            position={L.latLng(trip.startPoint.y, trip.startPoint.x)}
            ref={onRefAdded}
          >
            <Popup autoClose={false}>Start of the trip</Popup>
          </Marker>
          <Marker
            position={L.latLng(trip.stopPoint.y, trip.stopPoint.x)}
            ref={onRefAdded}
          >
            <Popup autoClose={false}>End of the trip</Popup>
          </Marker>
        </div>
      )}

      <Polyline
        positions={trip.points.map((point) => L.latLng(point.y, point.x))}
        color='#0000ff'
        ref={onRefAdded}
      >
        {showTripPathLabel && <Popup autoClose={false}>Trip Path</Popup>}
      </Polyline>
      {trip.exceptions &&
        trip.exceptions.length > 0 &&
        trip.exceptions.map((exception, key) => {
          return customExceptionIcon ? (
            <Marker
              key={key}
              position={L.latLng(exception.points[0].y, exception.points[0].x)}
              icon={customExceptionIcon}
              ref={onRefAdded}
            >
              <Popup autoClose={false}>{exception.name}</Popup>
            </Marker>
          ) : (
            <Marker
              key={key}
              position={L.latLng(exception.points[0].y, exception.points[0].x)}
              ref={onRefAdded}
            >
              <Popup autoClose={false}>{exception.name}</Popup>
            </Marker>
          );
        })}
    </div>
  );
};

export default TripMarker;
